import { age } from '../utils'

export const seo = {
  url: 'firma/omida-group',
  title: {
    pl: 'Omida Group',
    en: 'Omida Group',
    ua: 'Omida Group',
  },
  desc: {
    pl: `Omida Group jest jedną z najbardziej rozpoznawalnych marek w branży TSL w Polsce. Nasza siła tkwi w ludziach, dla których spedycja i transport to coś więcej niż tylko praca – to zamiłowanie i pasja.`,
    en: `Omida Group is one of the most recognizable brands in the TSL industry in Poland. Our strength lies in people for whom forwarding and transport is something more than just work - it is passion.`,
    ua: `Omida Group є одним із найбільш впізнаваних брендів у промисловості TSL у Польщі. Наша сила – в людях, для яких експедиція та транспорт – це щось більше, ніж просто робота – це любов і пристрасть.`,
  },
  keywords: [
    'omida',
    'omida group',
    'omida sea & air',
    'omida logistics',
    'starklog',
    'cargonite',
    // 'bpo champions',
  ],
}

export const intro = {
  title: {
    pl: 'Omida Group',
    en: 'Omida Group',
    ua: 'Omida Group',
  },
  desc: {
    pl: `Omida Group jest jedną z najbardziej rozpoznawalnych marek w branży TSL w Polsce. Nasza siła tkwi w ludziach, dla których spedycja i transport to coś więcej niż tylko praca – to zamiłowanie i pasja.`,
    en: `Omida Group is one of the most recognizable brands in the TSL industry in Poland. Our strength lies in people for whom forwarding and transport is something more than just work - it is passion.`,
    ua: `Omida Group є одним із найбільш впізнаваних брендів у промисловості TSL у Польщі. Наша сила – в людях, для яких експедиція та транспорт – це щось більше, ніж просто робота – це любов і пристрасть.`,
  },
}

export const main = {
  title: {
    pl: 'Jesteśmy częścią<br/> Omida Group',
    en: 'We are part of<br/> Omida Group ',
    ua: 'Ми є частиною<br/> Omida Group ',
  },
  body: {
    pl: 'Omida Group składa się z kilku spółek, które poprzez ścisłą współpracę i wymianę doświadczeń są w stanie zapewnić Klientom kompleksowość rozwiązań z zakresu spedycji oraz każdej gałęzi transportu w kraju i za granicą.',
    en: 'Omida Group consists of several companies, which through close cooperation and exchange of experience are able to provide customers with comprehensive solutions in the field of freight forwarding and every branch of transport at home and abroad.',
    ua: 'Omida Group складається з кількох компаній, які завдяки тісній співпраці та обміну досвідом здатні надавати клієнтам комплексні рішення у сфері експедирування та кожного виду транспорту в країні та за кордоном.',
  },
  sections: [
    {
      title: {
        pl: 'Omida Sea And Air S.A.',
        en: 'Omida Sea And Air S.A.',
        ua: 'Omida Sea And Air S.A.',
      },
      desc: {
        pl: `
        <p>
          Omida Sea And Air S.A. oferuje swoim Klientom pełen zakres usług związanych z obsługą spedycyjną transportu morskiego, lotniczego i kolejowego oraz najszerszy zakres ubezpieczeń w transporcie.
        </p>
        <p>
          Największy udział w działalności spółki stanowi przewóz ładunków skonteneryzowanych FCL, LCL oraz obsługa frachtu lotniczego.
        </p>
        <p>
          W działalności spółki odnotowuje się dynamiczny wzrost w obsłudze frachtów kolejowych (FCL/LCL) w relacji Chiny-Polska-Chiny.
        </p>
        <ul>
          <li>
            150 pracowników
          </li>
          <li>
            300 biur partnerskich na świecie
          </li>
          <li>
          33 458 TEU w 2022
          </li>
        </ul>
        `,
        en: `
        <p>
          Omida Sea And Air S.A. offers its customers a full range of sea, air and rail freight forwarding services and the widest range of transport insurance.
        </p>
        <p>
          The largest share of the company's activity is the transport of containerized FCL, LCL cargo and air freight services.
        </p>
        <p>
          The company's operations are noting dynamic growth in the service of rail freight (FCL/LCL) in China-Poland-China relations.
        </p>
        <ul>
          <li>
          150 employees
          </li>
          <li>
            300 partner offices worldwide
          </li>
          <li>
          37 177 TEU in 2023
          </li>
        </ul>
        `,
        ua: `
        <p>
          Omida Sea And Air S.A. пропонує своїм клієнтам повний спектр послуг, пов'язаних з морськими, повітряними та залізничними перевезеннями, а також найширший спектр страхування на транспорті.
        </p>
        <p>
          Найбільшу питому вагу в діяльності компанії займають перевезення вантажів у контейнерах FCL, LCL та авіаперевезення.
        </p>
        <p>
          Діяльність компанії демонструє динамічне зростання послуг залізничних вантажних перевезень (FCL / LCL) у відносинах Китай-Польща-Китай.
        </p>
        <ul>
          <li>
            150 працівників
          </li>
          <li>
            300 партнерських офісів по всьому світу
          </li>
          <li>
            37 177 TEU у 2023 році
          </li>
        </ul>
        `,
      },
    },
    {
      title: {
        pl: 'Omida VLS',
        en: 'Omida VLS',
        ua: 'Omida VLS',
      },
      desc: {
        pl: `
        <p>
          Omida VLS to marka, która łączy doświadczenie trzech uznanych spółek drogowych. Dzięki synergii wiedzy i ponad ${age}-letniemu doświadczeniu w transporcie, spedycji i logistyce staje się jednym z najbardziej rozpoznawalnych podmiotów na rynku europejskim.</p>
        <p>Jej misja to dostarczanie całościowych i nowoczesnych rozwiązań logistycznych, które odpowiadają na potrzeby współczesnego biznesu. Buduje trwałe relacje z klientami w oparciu o zaufanie, bezpieczeństwo i najwyższą jakość usług. Omida VLS to partner w logistyce, na którym możesz polegać.</p>
        `,
        en: `
        <p>
          Omida VLS is a brand that combines the expertise of three renowned road transport companies. With the synergy of knowledge and over ${age} years of experience in transportation, forwarding, and logistics, it is becoming one of the most recognizable entities in the European market.
        </p>
        <p>
          Its mission is to deliver comprehensive and modern logistics solutions that meet the needs of contemporary businesses. It builds lasting relationships with clients based on trust, safety, and the highest quality of service. Omida VLS is a logistics partner you can rely on.
        </p>`,
        ua: `
        <p>
          Omida VLS — це бренд, який об'єднує досвід трьох відомих компаній автомобільного транспорту. Завдяки синергії знань і понад ${age}-річному досвіду у сфері транспортування, експедирування та логістики, бренд стає одним із найвпізнаваніших на європейському ринку.
        </p>
        <p>
          Його місія — надавати комплексні та сучасні логістичні рішення, які відповідають потребам сучасного бізнесу. Omida VLS будує тривалі відносини з клієнтами, базуючись на довірі, безпеці та найвищій якості обслуговування. Omida VLS — це партнер у сфері логістики, на якого можна покластися.
        </p>`,
      },
    },
  ],
}
