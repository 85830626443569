import axios from 'axios'
import React, { useState, useEffect, useCallback } from 'react'
import { useLangContext } from '../context/lang.context'
import { s, sInput, sInputError, sFormTextBottom } from '../style'
import { validateEmail } from '../utils'
import Button from './Button'
import { sContainerNoTypes, sFormContainer } from './FormDynamic'
import FormFeedback from './FormFeedback'
import FormAgreements, { agreementsContent } from './FormAgreements'
import AdministrativeFormInfo from './AdministrativeFormInfo'
import Grid from './Grid'
import Main from './Main'

const content = {
  title: {
    pl: 'Newsletter',
    en: 'Newsletter',
    ua: 'Бюлетень',
  },
  desc: {
    pl: 'Zapisz się i zyskaj więcej. Dołącz do subskrybentów newslettera Omida Sea And Air. Bądź na bieżąco z aktualnościami i ofertami TSL.',
    en: 'Subscribe and gain more. Join the subscribers of the Omida Sea And Air newsletter. Stay up to date with news and TSL offers.',
    ua: 'Підпишіться та отримуйте більше. Приєднуйтесь до підписників інформаційного бюлетеня Omida Sea And Air. Будьте в курсі новин і пропозицій TSL.',
  },
  success: {
    title: { pl: 'Potwierdź subskrypcję!', en: 'Confirm the subscription!', ua: 'Підтвердьте підписку!', },
    text: {
      pl: 'Jeszcze jeden krok! Na podany adres email przesłaliśmy email z potwierdzeniem zapisu do newslettera. Jeżeli nie widzisz wiadomości, sprawdź skrzynkę spam.',
      en: 'Just one more step! We have sent you an email with subscription confirmation. Cannot see the message? Check the spam folder.',
      ua: 'Ще один крок! На вказану адресу електронної пошти ми надіслали електронний лист із підтвердженням підписки на розсилку. Якщо ви не бачите повідомлення, перевірте папку для спаму.',
    },
  },
  failure: {
    title: { pl: 'Niepowodzenie!', en: 'Failure!', ua: 'Провал!', },
    text: {
      pl: 'Wygląda na to, że Twoja wiadomość nie została wysłana. Skontaktuj się z nami drogą mailową lub telefonicznie',
      en: 'It looks like your message has not been sent. Contact us by e-mail or call us',
      ua: 'Схоже, ваше повідомлення не надіслано. Звяжіться з нами електронною поштою або по телефону',
    },
  },
  already: {
    title: { pl: 'Subskrybujesz!', en: 'Subscribing!', ua: 'Ви підписуєтеся!', },
    text: {
      pl: 'Twój adres email znajduje się na liście subskrybentów newslettera Omida. Niebawem najnowsze informacje pojawią się na Twojej skrzynce pocztowej!',
      en: 'Your email address has already been added to the Omida newsletter subscribers. The newest posts will appear on your mailbox soon!',
      ua: 'Ваша адреса електронної пошти є в списку підписників на розсилку Omida. Скоро актуальна інформація зявиться у вашій скриньці!',
    },
  },
  dictonary: {
    fill: {
      pl: 'Podaj email',
      en: 'Enter email',
      ua: 'Введіть свій email',
    },
    send: {
      pl: 'Subskrybuj',
      en: 'Subscribe',
      ua: 'Підпишіться',
    },
    fillagreements: {
      pl: 'Zaznacz zgodę',
      en: 'Agree to the terms',
      ua: 'Виберіть згоду',
    },
  }
}

const FormNewsletter = () => {
  const { lang } = useLangContext()

  const formType = 'NEWSLETTER'
  const initialFormData = {
    agreements: [],
  }

  const createInitialAgreements = (agreements) => {
    return agreements.map(agreement => ({
      subAgreements: agreement.subAgreements.map(sub => {
        if (sub.options) {
          return {
            values: Array(sub.options.length).fill(false)
          }
        }
        return false
      })
    }))
  }

  const [email, setEmail] = useState('')
  const [formData, setFormData] = useState(initialFormData)
  const [mailState, setMailState] = useState('unfilled')
  const [success, setSuccess] = useState(null)
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [wasSent, setWasSent] = useState(false)
  const [formAgreements, setFormAgreements] = useState([])
  const [code, setCode] = useState(0)

  useEffect(() => {
    const initialAgreements = createInitialAgreements(agreementsContent.agreements)
    setFormAgreements(initialAgreements)
  }, [formType])

  const validateAgreements = (agreements) => {
    if (!Array.isArray(agreements)) return false;
    
    // Get the first agreement's subAgreements array
    const firstAgreement = agreements[0]?.subAgreements;
    if (!Array.isArray(firstAgreement)) return false;
    
    // Check the first required checkbox (index 1, since index 0 is check-all)
    return firstAgreement[1] === true;
  };

  const validate = useCallback(() => {
    const isAgreementFilled = validateAgreements(formAgreements);
    return validateEmail(email) && isAgreementFilled;
  }, [email, formAgreements]);

  useEffect(() => {
    if (validate()) setMailState('filled')
    else setMailState('unfilled')
  }, [validate, setMailState])

  const onSend = (e) => {
    setSuccess(null)
    e.preventDefault()
    setWasSent(true)
    if (validate()) {
      setSuccess(null)
      setOpenBackdrop(true)
      setMailState('loading')
      axios({
        method: 'POST',
        url:
          process.env.NODE_ENV === 'development'
            ? 'http://localhost:5000/seaandair/newsletter/init'
            : 'https://cleverserver.appclever.pl/seaandair/newsletter/init',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        data: {
          email,
          campaignToken: 'QVklW',
          lang,
        },
      })
        .then((res) => {
          setSuccess(true)
          setEmail('')
          setOpenBackdrop(true)
          setWasSent(false)
          setCode(res.data.code)
          setFormAgreements(createInitialAgreements(agreementsContent.agreements));
        })
        .catch((error) => {
          console.log(error)
          setOpenBackdrop(true)
          setSuccess(false)
        })
    }
  }

  return (
    <>
      <Main
        h={2}
        s={1}
        title={content.title[lang]}
        desc={content.desc[lang]}
        subtitle={'SEA & AIR'}
      >
        <form onSubmit={onSend} css={sContainerNoTypes}>
          <Grid
            container
            ccss={[
              sFormContainer,
              {
                marginBottom: '4rem',
                marginTop: 0,
                [s.sm_down]: { marginBottom: '1rem' },
              },
            ]}
          >
            <Grid item xs={12}>
              <input
                type='email'
                name='email'
                placeholder='Email *'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                css={[
                  sInput,
                  wasSent && !validateEmail(email) && sInputError,
                  { marginBottom: 0, [s.md]: { marginBottom: 0 } },
                ]}
              />
            </Grid>
          </Grid>
          <FormAgreements
              formAgreements={formAgreements}
              setFormAgreements={setFormAgreements}
              wasSent={wasSent}
              content={agreementsContent}
              formType={formType}
              onAgreementsChange={(agreementData) => {
                if (Array.isArray(agreementData)) {
                  const updatedAgreements = agreementData.map(agreement => ({
                    ...agreement,
                    checkAll: agreement.subAgreements.every(sa => sa === true)
                  }));
                  
                  setFormData(prevData => ({
                    ...prevData,
                    agreements: updatedAgreements
                  }));
                  setFormAgreements(updatedAgreements);
                }
              }}
            />

          <AdministrativeFormInfo lang={lang} />
          <Button
              noMargin
              center
              reactiveBlack
              active={mailState === 'filled'}
              googleId={'send_button'}
              extraCss={{
                marginTop: '1.5rem',
                [s.sm_down]: { marginTop: '2rem' },
              }}
              action={onSend}>
              {mailState === 'filled' 
                ? content.dictonary.send[lang]
                : wasSent 
                  ? !validateEmail(email)
                    ? content.dictonary.fill[lang]
                    : !validateAgreements(formAgreements)
                      ? content.dictonary.fillagreements[lang]
                      : content.dictonary.send[lang]
                  : content.dictonary.send[lang]}
            </Button>
        </form>
      </Main>
      {openBackdrop && (
        <FormFeedback
          success={success}
          open={openBackdrop}
          setOpen={setOpenBackdrop}
          dataSuccess={code === 2 ? content.already : content.success}
          dataFailure={content.failure}
        />
      )}
    </>
  )
}

export default FormNewsletter
