export const seo = {
  url: 'firma/osiagniecia',
  title: {
    pl: 'Osiągnięcia',
    en: 'Achievements',
    ua: 'Досягнення',
  },
  desc: {
    pl: `Osiągnięte sukcesy i zdobyte nagrody są dla nas nie tylko źródłem dumy, ale także mobilizują nas do ciągłego rozwijania naszej oferty i świadczenia usług na najwyższym poziomie.`,
    en: `The successes achieved and the awards won are not only a source of pride for us, but also motivate us to constantly develop our offer and provide services at the highest level.`,
    ua: `Досягнуті успіхи та здобуті нагороди є для нас не тільки приводом для гордості, але й мотивують постійно розвивати нашу пропозицію та надавати послуги на найвищому рівні.`,
  },
  keywords: ['omida', 'sea and air', 'sea & air', 'osiągnięcia', 'nagrody tsl'],
}

export const intro = {
  title: {
    pl: 'Osiągnięcia',
    en: 'Achievements',
    ua: 'Досягнення',
  },
  desc: {
    pl: `Jesteśmy dumni z naszej historii i osiągniętych w tym czasie sukcesów!`,
    en: `We are proud of our history and the successes achieved during that time!`,
    ua: `Ми пишаємося своєю історією та досягнутими за цей час успіхами!`,
  },
}

export const main = {
  title: {
    pl: 'Osiągnięcia oraz nagrody<br/>Omida Sea And Air S.A.',
    en: 'Achievements & awards<br/>Omida Sea And Air S.A.',
    ua: 'Досягнення та нагороди<br/>Omida Sea And Air S.A.',
  },
  body: {
    pl: `Osiągnięte sukcesy i zdobyte nagrody są dla nas nie tylko źródłem dumy, ale także mobilizują nas do ciągłego rozwijania naszej oferty i świadczenia usług na najwyższym poziomie.`,
    en: `The successes achieved and the awards won are not only a source of pride for us, but also motivate us to constantly develop our offer and provide services at the highest level.`,
    ua: `Досягнуті успіхи та здобуті нагороди є для нас не тільки приводом для гордості, але й мотивують постійно розвивати нашу пропозицію та надавати послуги на найвищому рівні.`,
  },
  sections: [
    {
      title: {
        pl: 'Diament Miesięcznika Forbes',
        en: 'Forbes Diamond',
        ua: 'Діамант Forbes',
      },
      desc: {
        pl: 'Omida Group posiada prestiżowe nagrody miesięcznika Forbes – Diament Forbesa. Przyznawane są one najdynamiczniej rozwijającym się firmom w kraju. W 2019 roku zajęliśmy 2. miejsce w kategorii firm o poziomie przychodów powyżej 250 mln PLN w województwie pomorskim. W 2017 roku zajęliśmy pierwsze miejsce w rankingu pomorskich i ogólnopolskich Diamentów Forbesa w kategorii firm o obrotach od 50 do 250 mln PLN.',
        en: 'Omida Group holds the prestigious Forbes Diamond awards. They are awarded to the most dynamically developing companies in the country. In 2019, we were ranked 2nd in the category of companies with revenues above PLN 250 million in the Pomeranian region. In 2017, we took first place in the Pomeranian and national ranking of Forbes Diamonds in the category of companies with a turnover of PLN 50 to 250 million.',
        ua: 'Omida Group володіє престижною нагородою Forbes Diamond. Ними нагороджуються компанії країни, що найбільш динамічно розвиваються. У 2019 році ми посіли 2 місце в категорії компаній з доходом понад 250 мільйонів злотих у Поморському регіоні. У 2017 році ми посіли перше місце в поморському та національному рейтингу Forbes Diamonds у категорії компаній з оборотом від 50 до 250 мільйонів злотих.',
      },
    },
    {
      title: {
        pl: 'Gazele Biznesu',
        en: 'The Gazelles of Business',
        ua: 'Газелі бізнесу',
      },
      desc: {
        pl: 'Gazele Biznesu to nagrody wręczane przez Puls Biznesu od 2000 roku. Przyznawane są najdynamiczniej rozwijającym się małym i średnim przedsiębiorstwom. Omida Group została nagrodzona kolejno w 2014, 2015, 2016 i 2018 roku.',
        en: 'The Gazelles of Business awards have been bestowed by Puls Biznesu since 2000. They are awarded to the most dynamically developing small and medium enterprises. Omida Group was awarded in 2014, 2015, 2016 and 2018.',
        ua: 'Премію «Газелі бізнесу» вручає Puls Biznesu з 2000 року. Її вручають малим і середнім підприємствам, що найбільш динамічно розвиваються. Omida Group була нагороджена в 2014, 2015, 2016 і 2018 роках.',
      },
    },
    {
      title: {
        pl: 'Gryf Gospodarczy ',
        en: 'Economic Griffin',
        ua: 'Економічний Гріффін',
      },
      desc: {
        pl: 'Gryf Gospodarczy jedna z najbardziej prestiżowych nagród gospodarczych przyznawana przez władze i organizacje z województwa pomorskiego. Omida Group została laureatem „Gryfa Gospodarczego” w 2015 r. w kategorii średnich przedsiębiorstw zatrudniających od 50 do 249 pracowników.',
        en: 'Economic Griffin is one of the most prestigious economic awards granted by authorities and organizations of the Pomeranian region. Omida Group was awarded the "Economic Griffin" in 2015 in the category of medium-sized companies with 50 to 249 employees.',
        ua: 'Економічний грифон є однією з найпрестижніших економічних нагород, які присуджуються органами влади та організаціями Поморського регіону. Omida Group була нагороджена «Економічним грифоном» у 2015 році в категорії середніх компаній з кількістю співробітників від 50 до 249.',
      },
    },
  ],
}
