export const seo = {
  url: 'kariera',
  title: {
    pl: 'Kariera',
    en: 'Career',
  },
  desc: {
    pl: `Zobacz jakie możliwości daje praca w transporcie morskim i lotniczym. Sprawdź nasze aktualne oferty i aplikuj.`,
    en: `See the possibilities of working in sea and air transport. Check out our current offers and apply.`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'praca',
    'kariera',
    'spedytor',
    'logistyka',
    'TSL',
  ],
}

export const intro = {
  title: {
    pl: 'Kariera w TSL',
    en: 'Career in TSL',
  },
  desc: {
    pl: `Zobacz jakie możliwości daje praca w transporcie morskim i lotniczym. Sprawdź nasze aktualne oferty i aplikuj.`,
    en: `See the possibilities of working in sea and air transport. Check out our current offers and apply.`,
  },
}

export const main = {
  title: {
    pl: 'Rozpocznij karierę TSL<br/>w międzynarodowej spedycji<br/>Omida Sea And Air S.A.',
    en: 'Start your TSL career<br/>in an international forwarding<br/>Omida Sea And Air S.A.',
  },
  subtitle: {
    pl: 'Sea & Air Kariera',
    en: 'Sea & Air Career',
  },
  body: {
    pl: `
    <p>
    Jako Omida Sea And Air S.A. pojawiliśmy się na rynku w 2012 roku. Od początku naszej działalności staliśmy się znaczącym graczem na <strong>polskim rynku usług spedycyjnych</strong>. W szybkim tempie zbudowaliśmy <strong>profesjonalny zespół</strong> pracowników i międzynarodową sieć partnerów na świecie. Dzięki temu możemy oferować naszym Klientom <strong>poziom obsługi na najwyższym poziomie</strong>.    
    </p>
    <p>
    Codzienna, rzetelnie wykonywana praca, zaangażowanie i doświadczenie naszych pracowników zapewniają <strong>komfort i bezpieczeństwo</strong> Klientom powierzającym nam obsługę swoich kontraktów.
    </p>
    <p>
    </p>
    <p>
    Dbamy o <strong>dobrą i przyjazną atmosferę pracy</strong>. Z szacunkiem odnosimy się do naszych pracowników, wspierając ich <strong>rozwój osobisty i zawodowy</strong>. Wspólnie osiągane cele pozwalają nam <Strong>prowadzić naszą drużynę do zwycięstwa</strong>.
    </p>
    `,
    en: `
    <p>
    As Omida Sea And Air S.A. we entered the market in 2012. From the beginning of our activity, we have become a significant player on the <strong>Polish market of forwarding services</strong>. We quickly built a <strong> professional team </strong> of employees and an international network of partners around the world. Thanks to this, we can offer our clients <strong>the highest level of service</strong>.  
    </p>
    <p>
    Daily, diligently performed work, commitment and experience of our employees ensure <strong> comfort and safety </strong> to clients who entrust us with the handling of their contracts.
    </p>
    <p>
    </p>
    <p>
    We take care of a <strong>good and friendly working atmosphere</strong>. We respect our employees, supporting their <strong>personal and professional development</strong>. The goals we achieve together allow us to <strong>lead our team to victory</strong>.
    </p>
    `,
  },
  sections: [
    {
      title: {
        pl: 'Dlaczego właśnie my?',
        en: 'Why choose us?',
      },
      desc: {
        pl: `
        Jeśli interesuje Cię praca w stabilnej, ale dynamicznie rozwijającej się firmie o uznanej marce na rynku, jeśli planujesz zdobyć praktyczną wiedzę i doświadczenie, pracując w profesjonalnym i życzliwym zespole ludzi, jeśli masz poczucie humoru i dystans do samego siebie to Omida Sea And Air S.A. jest idealnym miejscem dla Ciebie!
        `,
        en: `
        If you are interested in working in a stable, but dynamically developing company with a recognized brand on the market, if you plan to gain practical knowledge and experience working in a professional and friendly team of people, if you have a sense of humor and distance to yourself, then Omida Sea And Air S.A. is the perfect place for you!
        `,
      },
    },
  ],
}

export const features = {
  title: {
    pl: 'Multum korzyści dla Ciebie',
    en: 'Many benefits for you',
  },
  desc: {
    pl: 'Dbamy o każdego pracownika i zapewniamy nowoczesne otoczenie sprzyjające dobremu samopoczuciu w pracy. Omida Sea And Air S.A. tworzą ludzie, dlatego zwracamy szczególną uwagę na to, czego potrzebują i co jest zgodne z wartościami naszej firmy.',
    en: "We take care of every employee and provide a modern environment conducive to well-being at work. Omida Sea And Air S.A. are created by people, which is why we pay special attention to what they need and what is in line with our company's values.",
  },
  features: [
    {
      title: {
        pl: 'Onboarding',
        en: 'Onboarding',
      },
      desc: {
        pl: 'Otrzymasz wsparcie od opiekuna, który krok po kroku wprowadzi Cię w nowe obowiązki.',
        en: 'You will receive support from a tutor who will introduce you to new responsibilities step by step.',
      },
      icon: 'onboarding',
    },
    {
      title: {
        pl: 'Motywacja',
        en: 'Motivation',
      },
      desc: {
        pl: 'Przygotowaliśmy dla Ciebie dodatkowy system premiowy.',
        en: 'We have prepared an additional bonus system for you.',
      },
      icon: 'motivation',
    },
    {
      title: {
        pl: 'Dofinansowanie',
        en: 'Co-financing',
      },
      desc: {
        pl: 'Między innymi Karty Multisport i wiele więcej!',
        en: 'Among other things, Multisport Cards and much more!',
      },
      icon: 'money',
    },
    {
      title: {
        pl: 'Elastyczne warunki',
        en: 'Flexible terms',
      },
      desc: {
        pl: 'Możesz wybrać taką formę zatrudnienia, jaka Ci najbardziej odpowiada - wybierz umowę o pracę lub współpracę na zasadzie B2B.',
        en: 'You can choose the form of employment that suits you best - choose a contract of employment or cooperation on a B2B basis.',
      },
      icon: 'employment',
    },
    {
      title: {
        pl: 'Przyjazna atmosfera',
        en: 'Friendly atmosphere',
      },
      desc: {
        pl: 'Przyjazna atmosfera oraz imprezy firmowe i szkolenia.',
        en: 'Friendly atmosphere and corporate events and training. ',
      },
      icon: 'person',
    },
    {
      title: {
        pl: 'Ubezpieczenie',
        en: 'Insurance',
      },
      desc: {
        pl: 'Ubezpieczenie i możliwość korzystania z prywatnej opieki medycznej.',
        en: 'Insurance and the possibility of using private medical care.',
      },
      icon: 'health',
    },
  ],
}

export const join = {
  title: {
    pl: 'Jak do nas dołączyć?',
    en: 'How to join us?',
  },
  subtitle: {
    pl: 'Sea & Air Kariera',
    en: 'Sea & Air Career',
  },
  desc: {
    pl: 'Rekrutacja w Omida Sea And Air S.A. dzieli się na kilka etapów. Poznając każdy z nich, możesz odpowiednio się przygotować.',
    en: 'Recruitment at Omida Sea And Air S.A. is divided into several stages. By getting to know each of them, you can prepare yourself properly.',
  },
  stages: [
    {
      q: {
        pl: 'ETAP I',
        en: 'STAGE I',
      },
      a: {
        pl: 'Na pierwszym etapie zapoznasz się ze wszystkimi ofertami pracy. To właśnie w tym miejscu możesz wybrać interesujące Cię stanowisko i wysłać do nas swoją aplikację.',
        en: 'In the first stage, you will get acquainted with all job offers. This is where you can choose the position you are interested in and send us your application.',
      },
    },
    {
      q: {
        pl: 'ETAP II',
        en: 'STAGE II',
      },
      a: {
        pl: 'Analizujemy każde CV i wybieramy kandydatów, z którymi chcielibyśmy się spotkać. Po wstępnej rozmowie telefonicznej umawiamy się na spotkanie.',
        en: 'We analyze each CV and select candidates with whom we would like to meet. After the initial telephone conversation, we arrange a meeting.',
      },
    },
    {
      q: {
        pl: 'ETAP III',
        en: 'STAGE III',
      },
      a: {
        pl: 'Sprawdzamy wiedzę każdego kandydata i dopytujemy o doświadczenie w branży transportowej. Na rozmowach daj z siebie wszystko. Chcemy poznać Twoje mocne strony i dowiedzieć się, w jakim modelu pracujesz najbardziej wydajnie.',
        en: 'We check the knowledge of each candidate and ask about experience in the transport industry. Do your best during the interviews. We want to know your strengths and find out in which model you work most efficiently.',
      },
    },
    {
      q: {
        pl: 'ETAP IV',
        en: 'STAGE IV',
      },
      a: {
        pl: 'Na ostatnim etapie podejmujemy decyzję dotyczącą współpracy.',
        en: 'At the last stage, we make a decision regarding cooperation.',
      },
    },
  ],
}

// export const video = {
//   title: {
//     pl: 'Zobacz nasze biuro',
//     en: 'See our office',
//   },
//   video: 'https://www.youtube.com/embed/aJRXUBxyN_c',
// }

export const video = {
  title: {
    pl: 'Zapraszamy na pokład!',
    en: 'Come on board!',
  },
  desc: {
    pl: '<div>Znajdziesz nas w 6 oddziałach, wybierz, do którego jest Ci najbliżej. W pracy dbamy o dobrą atmosferę  między pracownikami, liderami i całym zespołem. Nasze oddziały znajdziecie w <strong>Gdyni</strong>, <strong>Warszawie</strong>, <strong>Wrocławiu</strong>, <strong>Krakowie</strong>, <strong>Katowicach</strong> i <strong>Słubicach</strong>. Zapraszamy do oglądania relacji z Krakowa oraz Warszawy!</div>',
    en: '<div>You will find us in 6 branches, choose which one is closest to you. At work, we ensure a good atmosphere between employees, leaders and the entire team. You will find our branches in <strong>Gdynia</strong>, <strong>Warsaw</strong>, <strong>Wrocław</strong>, <strong>Cracow</strong>, <strong>Katowice</strong> and <strong>Słubice</strong>. We invite you to watch the report from Cracow and Warsaw!</div>',
  },
  video: 'https://www.youtube.com/embed/JJVo_SP-dbA',
  videos: [
    'https://www.youtube.com/embed/NaLogGdsW2w',
    'https://www.youtube.com/embed/aJRXUBxyN_c',
    'https://www.youtube.com/embed/yER1jAiY1k4',
  ],
}

export const supporting = {
  title: {
    pl: 'Wspieramy aktywność sportową!',
    en: 'We support sports activities!',
  },
  video: 'https://www.youtube.com/embed/cWQBf9tK7Rk',
  videos: [
    'https://www.youtube.com/embed/8hpzS8kNXos',
    'https://www.youtube.com/embed/bw1eio2k_Pc',
  ],
}
